// extracted by mini-css-extract-plugin
export var galleryPopup = "GalleryPopup-module--gallery-popup--96-9l";
export var galleryPopupOpen = "GalleryPopup-module--gallery-popup--open--Kjuvl";
export var galleryPopupFadeIn = "GalleryPopup-module--galleryPopupFadeIn--Iu4wi";
export var galleryPopup__close = "GalleryPopup-module--gallery-popup__close--XFKWy";
export var galleryPopup__carousel = "GalleryPopup-module--gallery-popup__carousel--LEz75";
export var sliderWrapper = "GalleryPopup-module--slider-wrapper--6I-Us";
export var galleryPopup__leftArrow = "GalleryPopup-module--gallery-popup__left-arrow--fNVAi";
export var galleryPopup__rightArrow = "GalleryPopup-module--gallery-popup__right-arrow--VHKnD";
export var galleryPopup__imageWrapper = "GalleryPopup-module--gallery-popup__image-wrapper--R5Qtn";
export var galleryPopup__imageWrapperWrapper = "GalleryPopup-module--gallery-popup__image-wrapper-wrapper--SCyxU";
export var galleryPopup__image = "GalleryPopup-module--gallery-popup__image--oIiNU";
export var galleryPopup__imageCaption = "GalleryPopup-module--gallery-popup__image-caption--iyaYo";
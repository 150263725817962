import React from 'react'

import FluidImage from './FluidImage';

import * as styles from './CoverImage.module.css';

export const CoverImage = ({
  yellowBusiness,
  className,
}) => {
  const [isMobile, setIsMobile] = React.useState(false);
  
  React.useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < 600);
    }
    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <div className={className}>
        <FluidImage
          className={`${styles.cover}`}
          fluid={yellowBusiness?.website?.gatsbyImageCoverImage?.childImageSharp?.fluid}
          src={yellowBusiness?.website?.coverImage}
          alt={`Banner image for ${yellowBusiness?.name}`}
          aspectRatio={(isMobile) ? 1 / 1 : 16 / 8}
          critical={true}
        />
        {(yellowBusiness?.website?.gatsbyImageCoverImage || yellowBusiness?.website?.coverImage) && (
          <div
            // cover image darkening overlay
            className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-black"
            style={{ opacity: 0.4 }}
          ></div>
        )}
      </div>
    </>
  )
};
import React from 'react';

import { FluidImage, ThemeContext } from '@websites/components';
import GalleryPopup from '../GalleryPopup/GalleryPopup';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import * as styles from './Gallery.module.css';

function Gallery({ gallery }) {
  const { text } = React.useContext(ThemeContext);

  const [loadFullImage, setLoadFullImage] = React.useState(false);
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [galleryImageIndex, setGalleryImageIndex] = React.useState(0);
  const [galleryTransitionTime, setGalleryTransitionTime] = React.useState(300);

  function openPopup(imageIndex) {
    setPopupVisible(true);
    setGalleryTransitionTime(0);
    setGalleryImageIndex(imageIndex);
    window.setTimeout(() => { setGalleryTransitionTime(300); }, 0);
  }

  function closePopup() {
    setPopupVisible(false);
  }

  // defer loading of full image
  React.useEffect(() => {
    window.setTimeout(() => {
      setLoadFullImage(true);
    }, 500);
  }, []);

  return (
    <>
      <div className={styles.gallery}>
        {gallery.map((image, imageIndex) => (
          <button key={`gallery_${imageIndex}`} type="button" onClick={() => { openPopup(imageIndex); }} className={styles.galleryItem}>
            <div className={styles.gallery__imageWrapper}>
              <FluidImage
                fluid={image?.fluid}
                src={image?.contentUrl}
                alt={image?.caption}
                aspectRatio={411 / 296}
              />
            </div>
            <p className={`${text('title')} mb-0 mt-1 sm:mt-2 text-base font-semibold`}>{image?.caption}</p>
          </button>
        ))}
      </div>
      
      {/* Popup */}
      <GalleryPopup
        gallery={gallery}
        loadFullImage={loadFullImage}
        galleryImageIndex={galleryImageIndex}
        galleryTransitionTime={galleryTransitionTime}
        popupVisible={popupVisible}
        closePopup={closePopup}
      />
    </>
  );
}

export default Gallery;

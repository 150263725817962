import React from 'react';
import Img, { FluidObject } from 'gatsby-image';

interface IFluidImage {
  fluid?: FluidObject
  src?: string
  alt?: string
  aspectRatio?: number
}

const FluidImage = ({
  fluid,
  src,
  alt,
  className,
  fit = 'cover',
  aspectRatio = 1,
  critical = false,
}: IFluidImage) => {
  const percentageRatio = `${((1 / aspectRatio) * 100).toString()}%`;

  if (!fluid && !src) return null;

  return (
    <div
      className={`relative h-0 overflow-hidden ${className}`}
      style={{ paddingTop: percentageRatio, background: 'white' }}
    >
      {fluid ? (
        <Img
          critical={critical}
          className="absolute inset-0"
          fluid={fluid}
          alt={alt}
          style={{ position: 'absolute' }} // because gatsby-image tries to overwrite it
          imgStyle={{ objectFit: fit }} // because className goes to container, not to the image
        />
      ) : (
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={src}
          alt={alt}
        />
      )}
    </div>
  );
};

FluidImage.defaultProps = {
  fluid: null,
  src: '',
  alt: '',
  aspectRatio: 1,
  critical: false,
};

export default FluidImage;

import React from 'react';

import { Section } from "../Section/Section";
import { RichTextHTML } from "../RichTextHTML";
import { ThemeContext } from '../ThemeContext';
import FluidImage from '../FluidImage';

export const SectionServices = ({ section, yellowBusiness }) => {
  const { text, rounded } = React.useContext(ThemeContext);

  return (
    <>
      {section && (
        <Section section={section}>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
            {yellowBusiness?.products?.map((product, index) => (
              <div
                key={index}
                id={product.id}
                className={`bg-white flex flex-col ${rounded('lg')} shadow-md hover:shadow-lg overflow-hidden`}
              >
                <FluidImage
                  fluid={product?.gatsbyImageImage?.childImageSharp?.fluid}
                  src={product.image}
                  alt={`Image for product named ${product?.name}`}
                  aspectRatio={4 / 3}
                />
                <div className="flex-1 p-4 lg:p-5 xl:p-6">
                  {product?.name && (
                    <h2 className={`text-lg md:text-xl font-bold mb-2 ${text('base-dark')}`}>
                      {product?.name}
                    </h2>
                  )}
                  {product?.description && (
                    <RichTextHTML className={`text-md md:text-md ${text('base-dark')}`}>
                      {product?.description}
                    </RichTextHTML>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Section>
      )}
    </>
  )
}
